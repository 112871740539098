<template>
    <div>
        <nav-bar title="问题反馈" url="/"></nav-bar>

        <!--用户信息-->
        <div class="top">
            <div class="title">
                <div class="title-text">反馈内容</div>
                <div class="title-bg">
                    <img src="@/static/image/index/6.png">
                </div>
            </div>
            <van-cell-group>
                <van-field v-model="content" rows="4" autosize type="textarea" maxlength="200" placeholder="请输入反馈内容"
                    show-word-limit></van-field>
            </van-cell-group>
        </div>

        <div class="top">
            <div class="title">
                <div class="title-text">附件资料</div>
                <div class="title-bg">
                    <img src="@/static/image/index/6.png">
                </div>
            </div>
            <div class="other">
                <div class="upload" >
                    <van-uploader v-model="image" multiple max-count="9" />
                </div>
            </div>
        </div>


        <!--办理须知和提交按钮-->
        <div class="action">
            <van-button type="primary" round @click="submit" color="#00afff" size="large" :disabled="!content"
                :color="config.main_color">提交反馈
            </van-button>
        </div>


        <!--常见问题-->
        <div class="top">
            <div class="title">
                <div class="title-text">常见问题</div>
                <div class="title-bg">
                    <img src="@/static/image/index/6.png">
                </div>
            </div>
            <van-collapse v-model="activeName">
                <van-collapse-item :title="index + 1 + '、' + item.title" :name="item.id" v-for="(item,index) in list"
                    :key="index">
                    <div class="about">
                        {{item.content}}
                    </div>
                </van-collapse-item>
            </van-collapse>
        </div>


    </div>
</template>

<script>
    import { oauth } from "@/static/js/oauth";
    import {
        bug_question,
        bug_submit
    } from '@/api/bug.js';
    import {
        Toast,
        Dialog
    } from 'vant';
    export default {
        name: 'bug',
        data() {
            return {
                content: undefined,
                image: [],
                list: [],
                activeName: [],
            }
        },
        mounted() {
            oauth();
            this.get_data();
        },
        methods: {
            //提交
            submit() {
                var that = this;
                if (!this.content) {
                    Toast('反馈内容不得为空');
                    return false;
                }
                var data = {
                    content: this.content,
                    image: this.image,
                    loading: true,
                    loading_text: '提交中',
                };
                bug_submit(data).then(res => {
                    if (res.code == 1) {
                        Dialog.alert({
                            message: res.msg,
                        }).then(function () {
                            //提交成功
                            that.url('/bug_list');
                        });
                    }
                });
            },

            get_data() {
                bug_question({}).then(res => {
                    if (res.code == 1) {
                        this.list = res.data;
                    }
                });
            },

            url(path) {
                if (path.indexOf("http") != -1) {
                    window.location.href = path;
                } else {
                    if (path == this.$store.getters.unionid) {
                        this.$router.back();
                    } else {
                        this.$router.push(path);
                    }
                }
            },
        }
    }
</script>

<style scoped>
    .top {
        width: calc(100% - 40px);
        margin: 20px auto;
        background: #fff;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
    }

    .title {
        background: var(--main-color);
        height: 40px;
        line-height: 40px;
        color: #fff;
        padding-left: 20px;
        position: relative;
        font-family: 'FZCS';

    }

    .title-bg {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 999;
    }

    .title-bg img {
        height: 40px;
    }

    .van-button--primary {
        background-image: url('../../../static/image/index/7.png') !important;
        background-size: 100% auto !important;
        background-repeat: no-repeat;
        font-family: 'FZCS';
    }

    /deep/ .label {
        font-family: 'FZCS';
    }

    /deep/ .label,
    /deep/ .van-field__control {
        color: var(--main-color);
    }

    .action {
        margin: 20px;
        width: calc(100% - 40px);
    }

    .about {
        font-family: 'FZCS';
        color: var(--main-color);
        border: 1px solid var(--main-color);
        background: #D8F1E9;
        border-radius: 10px;
        padding: 10px;

    }

    /deep/ .van-cell__title {
        color: var(--main-color);
    }

    .upload{
        background: #fff;
        padding-top: 10px;
        padding: 10px;
    }

    /deep/ .van-uploader__upload-icon{
        color: var(--main-color);
    }
    /deep/ .van-uploader__upload{
        background: #EAF9F5;
    }
</style>